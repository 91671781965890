import { FunctionComponent, ReactElement, SVGProps } from 'react';

import { PropsOf } from '@emotion/react';

import * as icons from './svg';

export type IconType = keyof typeof icons;
export const iconTypes: IconType[] = Object.keys(icons) as IconType[];

export interface IconProps
  extends PropsOf<
    FunctionComponent<
      SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >
  > {
  icon: IconType;
  color?: string;
  stroke?: string;
  size?: string | number;
  rotate?: number;
  className?: string;
  onClick?: (event?: any) => void;
}

const NORMAL_STROKE_COLOR = '#0A0400';

function Icon({
  icon,
  color,
  stroke, // Line Color
  size,
  rotate,
  className,
  onClick,
  ...props
}: IconProps): ReactElement {
  const SVGIcon = icons[icon];
  const isFill = /Fill/.test(icon);

  const strokeColor = stroke || (isFill ? NORMAL_STROKE_COLOR : '');
  const widthPx =
    size &&
    (typeof size === 'number' ? `${size}px` : `${size.replace('px', '')}px`);

  return (
    <SVGIcon
      onClick={onClick}
      css={{
        color: `${strokeColor} !important`,
        width: widthPx,
        height: 'auto',
        transform: `rotate(${rotate || 0}deg)`,
      }}
      className={className}
      {...props}
    />
  );
}

export default Icon;
